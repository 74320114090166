<template>
  <div class="about-components-index-wrapper">
    <div
      class="about-index-introduction"
    >
      <div class="about-index-introduction-title">{{ $t('aboutIntroductionTitle') }}</div>
      <div class="about-index-introduction-des">
        <p>{{ $t('aboutIntroductionDes[0]') }}</p>
        <p>{{ $t('aboutIntroductionDes[1]') }}</p>
        <p>{{ $t('aboutIntroductionDes[2]') }}</p>
      </div>
    </div>
    <div class="section section-one">
      <div class="section-one-nav">
        <div
          v-for="(item, index) in $t('aboutIntroductionValue')"
          :key="item"
          :class="valueActive === index ? 'active' : ''"
          @click="valueActive = index"
        >
          {{ item }}
        </div>
      </div>
      <div class="section-one-content">
        {{ $t('aboutIntroductionValueDes')[valueActive] }} 
      </div>
    </div>
    <div
      ref="aboutDevelopment"
      class="about-index-development"
    >
      <div class="content">
        <div class="year">
          <div 
            v-for="(item, index) in developmentList"
            :key="index"
            :class="developmentSwiperActive === (index + 1) ? 'active' : ''"
            @click="slideToLoop(index)"
          >
            {{ item.year }}
          </div>
        </div>
        <div class="content-swiper-scrollbar-wrapper">
          <div class="content-swiper-scrollbar"></div>
        </div>
        <swiper
          ref="mySwiper"
          class="section-swiper"
          :options="developmentSwiperOptions"
          @slideChangeTransitionStart="slideChangeTransitionEnd"
          @ready="swiperLoad"
        >
           <template
            v-for="(item, index) in developmentList"
          >
            <swiper-slide
              :key="index"
              class="development-slide"
            > 
              <div
                 class="development-slide-img"
              >
                <img
                  :src="item.image"
                />
              </div>
              <div
                class="development-slide-des"
              >
                <p
                >
                  {{ $t(item.prop) }}
                </p>
              </div>
            </swiper-slide>
          </template>
        </swiper>
      </div>
    </div>
    <div
      ref="aboutRwards"
      class="about-index-awards"
    >
      <div class="title">
        {{ $t('awards') }}
      </div>
      <div class="about-index-awards-swiper">
        <swiper
          :options="awardsSwiperOptions"
        >
           <template
            v-for="(item, index) in awardsList"
          >
            <swiper-slide
              :key="index"
              class="awards-slide"
            > 
              <div
                 class="awards-slide-img"
              >
                <img
                  :src="item.imgage"
                />
              </div>
              <div
                class="awards-slide-des"
              >
                <div class="awards-slide-des-title">
                  {{ $t(item.prop)[index].title }}
                </div>
                <div class="awards-slide-des-des">
                  {{ $t(item.prop)[index].des }}
                </div>
              </div>
            </swiper-slide>
          </template>
        </swiper>
      </div>
    </div>
  </div>
</template>

<script>
import { Swiper, SwiperSlide } from 'vue-awesome-swiper'
import { configAbout } from '@/utils/config'
export default {
  name: 'AboutComponentsIndex',
  components: {
    Swiper,
    SwiperSlide
  },
  data() {
    return {
      valueActive: 0,
      developmentList: configAbout.developmentList,
      swiper: null,
      developmentSwiperOptions: {
        scrollbar: {
          el: ".content-swiper-scrollbar",
          dragSize: 40,
        },
        spaceBetween: 10,
      },
      developmentSwiperActive: 1,
      awardsSwiperOptions: {
        loop: true,
        slidesPerView: "auto",
        spaceBetween: 10,
      },
      awardsList: configAbout.awardsList
    }
  },
  mounted() {
    this.scrollto()
  },
  beforeRouteUpdate(to, from, next) {
    next()
    if (to.query.scrollto) this.scrollto(to.query.scrollto)
  },
  methods: {
    swiperLoad() {
      this.swiper = this.$refs.mySwiper.$swiper
      this.developmentSwiperActive = this.getActiveIndex()
    },
    getActiveIndex() {
      return this.swiper.realIndex + 1
    },
    slideChangeTransitionEnd() {
      this.developmentSwiperActive = this.getActiveIndex()
    },
    slideToLoop(index) {
      this.swiper.slideToLoop(index, 300)
    },
    scrollto(to) {
      const scrollto = to || this.$route.query.scrollto
      if (scrollto && this.$refs[scrollto]) {
        this.$nextTick(() => {
          document.documentElement.scrollTop = this.$refs[scrollto].offsetTop - 70
        })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.about-components-index-wrapper {
  width: 100%;
  overflow: hidden;
  margin: 0 auto;
  .about-index-introduction {
    margin: 0 auto;
    background: #fff;
    padding: 22px 20px 46px 20px;
    .about-index-introduction-title {
      color: rgba(34, 34, 34, 1);
      font-size: 20px;
      line-height: 36px;
      text-align: center;
      font-weight: bold;
    }
    .about-index-introduction-des {
      color: rgba(102, 102, 102, 1);
      font-size: 13px;
      line-height: 24px;
      margin-top: 12px;
      p {
        margin-bottom: 8px;
      }
    }
  }
  .section-one {
    background: rgba(245, 246, 247, 1);
    padding: 24px 20px 32px 20px;
    &-nav {
      display: flex;
      font-size: 14px;
      font-weight: 600;
      color: rgba(102, 102, 102, 1);
      line-height: 22px;
      border-bottom: 2px solid rgb(161, 156, 156, 0);
      overflow: scroll;
      & > div {
        margin-right: 32px;
        height: 30px;
        width: fit-content;
        white-space: nowrap;
        &:last-child {
          margin-right: 0;
        }
        &.active {
          color: #000;
          border-bottom: 2px solid #000;
        }
      }
    }
    &-content {
      padding: 20px;
      margin-top: 10px;
      font-size: 13px;
      color: #666666;
      line-height: 22px;
      background: #fff;
      border-radius: 10px;
    }
  }
  .about-index-development {
    background: #fff;
    padding: 30px 20px;
    .content {
      .year {
        display: flex;
        justify-content: space-between;
        color: rgba(102, 102, 102, 1);
        font-size: 14px;
        & > div {
          transition: all 0.3s;
          line-height: 30px;
          width: 65px;
          text-align: center;
          &.active, &:hover {
            color: #000;
            font-weight: bold;
            transition: all 0.3s;
          }
        }
      }
      .content-swiper-scrollbar-wrapper {
        height: 2px;
        background: rgba(225, 225, 225, 1);
        width: 95%;
        overflow: hidden;
        margin: 16px auto;

        .content-swiper-scrollbar {
          box-sizing: border-box;
          height: 2px;
          ::v-deep .swiper-scrollbar-drag {
            background: #000;
          }
        }
      }
      .section-swiper {
        ::v-deep .swiper-wrapper {
          .development-slide {
            width: 100%;
            background: #FFFFFF;
            border-radius: 10px;
            border: 1px solid #E1E1E1;
            padding: 13px;
            height: auto;
            .development-slide-img {
              width: 100%;
              height: 340px;
              display: flex;
              justify-content: center;
              overflow: hidden;
              img {
                object-fit: cover;
              }
            }
            .development-slide-des {
              padding-top: 16px;
              font-size: 13px;
              color: #666666;
              line-height: 22px;
            }
          }
        }

      }

    }
  }
  .about-index-awards {
    padding: 20px;
    .title {
      font-size: 20px;
      color: rgba(34, 34, 34, 1);
      font-weight: bold;
      text-align: center;
      margin-bottom: 20px;
    }
    .about-index-awards-swiper {
      width: 100%;
      margin: 0 auto;
      display: flex;
      justify-content: center;
      ::v-deep .awards-slide {
        width: 303px;
        overflow: hidden;
        background: #F5F6F7;
        border-radius: 10px;
        padding: 20px;
        &-img {
          width: 200px;
          margin: 0 auto;
          img {
            width: 100%;
          }
        }
        &-des {
          &-title {
            margin: 16px 0;
            font-size: 16px;
            font-weight: bold;
            color: #000000;
            line-height: 22px;
          }
          &-des {
            font-size: 13px;
            font-weight: 400;
            color: #666666;
            line-height: 22px;
          }
        }
      }
    }
  }
}
</style>